import styled from 'styled-components'

// const columnSetter = (props: { column: string }) => props.column

const styledComponents: any = {
  BodyComponent: styled.div`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 120px;
  `,
  MainComponent: styled.div`
    grid-row: 1 / 2;
    overflow-y: scroll;
  `,
  FileComponent: styled.div`
    height: 280px;
    width: 100%;
  `,
  ClickableFileComponent: styled.button`
    height: 100%;
    width: 100%;
    border: 0.5px solid #000000;
    border-radius: 4px;
    background-color: rgba(0,0,0,0.3);
    color: #ffffff;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,1));
    cursor: pointer;
    outline: none;
  `,
  FooterComponent: styled.div`
    grid-row: 2 / 3;
    padding: 20px;
  `,
  PDFUploaderComponent: styled.div`
    width: 90%;
    height: 100%;
    margin: 0 auto;
    background-color: #fffacd;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
  `,
  LoaderWrapperComponent: styled.div`
    height: 100vh;
    width: 100vw;
    z-index: 10;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
  `,
  LoaderBackgroundComponent: styled.div`
    position: absolute;
    top: calc(50vh - 150px);
    left: calc(50vw - 75px);
  `,
  CommandButtonComponent: styled.button`
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #fffacd;
    color: #696969;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
    cursor: pointer;
    outline: none;
  `,
  CommandButtonWrapperComponent: styled.div`
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 4px;
  `,
  ThumbnailImageComponment: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `
}

export default styledComponents
