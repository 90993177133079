import React from 'react'
import { Helmet } from 'react-helmet'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import Main from '@container/Main'
import SignUp from '@container/SignUp'
import SignIn from '@container/SignIn'
import Verification from '@container/Verification'
import SignOut from '@container/SignOut'

const App: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Warehouse</title>
      </Helmet>
      <Router>
        <Switch>
          <Route path='/' component={Main} exact />
          <Route path='/signup' component={SignUp} exact />
          <Route path='/signin' component={SignIn} exact />
          <Route path='/verification' component={Verification} exact />
          <Route path='/signout' component={SignOut} exact />
          <Redirect to='/' />
        </Switch>
      </Router>
    </>
  )
}

export default App
