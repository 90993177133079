import Models from '@models'
import * as ActionType from './types'

export const uploadFile = {
  started: (request: Models.UploadFileRequest) => {
    return {
      type: ActionType.STARTED_UPLOAD_FILE as typeof ActionType.STARTED_UPLOAD_FILE,
      payload: request,
    }
  },
  succeeded: (request: Models.UploadFileRequest, response: Models.File[]) => ({
    type: ActionType.SUCCEEDED_UPLOAD_FILE as typeof ActionType.SUCCEEDED_UPLOAD_FILE,
    payload: { request, response },
  }),
  failed: (request: Models.UploadFileRequest, response: Models.File[]) => ({
    type: ActionType.FAILED_UPLOAD_FILE as typeof ActionType.FAILED_UPLOAD_FILE,
    payload: { request, response },
  }),
}

export const downloadFile = {
  started: (request: Models.DownloadFileRequest) => {
    return {
      type: ActionType.STARTED_DOWNLOAD_FILE as typeof ActionType.STARTED_DOWNLOAD_FILE,
      payload: request,
    }
  },
  succeeded: (request: Models.DownloadFileRequest) => ({
    type: ActionType.SUCCEEDED_DOWNLOAD_FILE as typeof ActionType.SUCCEEDED_DOWNLOAD_FILE,
    payload: { request },
  }),
  failed: (request: Models.DownloadFileRequest) => ({
    type: ActionType.FAILED_DOWNLOAD_FILE as typeof ActionType.FAILED_DOWNLOAD_FILE,
    payload: { request },
  }),
}

export const getFiles = {
  started: () => {
    return {
      type: ActionType.STARTED_GET_FILES as typeof ActionType.STARTED_GET_FILES,
      payload: {},
    }
  },
  succeeded: (response: Models.GetFilesResponse) => {
    return {
      type: ActionType.SUCCEEDED_GET_FILES as typeof ActionType.SUCCEEDED_GET_FILES,
      payload: { response },
    }
  },
  failed: (response: Models.GetFilesResponse) => {
    return {
      type: ActionType.FAILED_GET_FILES as typeof ActionType.FAILED_GET_FILES,
      payload: { response },
    }
  },
}

export const uploadPdfThumbnail = {
  started: (request: Models.UploadPdfThumbnailRequest) => {
    return {
      type: ActionType.STARTED_UPLOAD_PDF_THUMBNAIL as typeof ActionType.STARTED_UPLOAD_PDF_THUMBNAIL,
      payload: request,
    }
  },
  succeeded: (request: Models.UploadPdfThumbnailRequest, response: Models.File[]) => {
    return {
      type: ActionType.SUCCEEDED_UPLOAD_PDF_THUMBNAIL as typeof ActionType.SUCCEEDED_UPLOAD_PDF_THUMBNAIL,
      payload: { request, response },
    }
  },
  failed: (request: Models.UploadPdfThumbnailRequest, response: Models.File[]) => {
    return {
      type: ActionType.FAILED_UPLOAD_PDF_THUMBNAIL as typeof ActionType.FAILED_UPLOAD_PDF_THUMBNAIL,
      payload: { request, response },
    }
  },
}

export type FilesAction =
  | ReturnType<typeof getFiles.started>
  | ReturnType<typeof getFiles.succeeded>
  | ReturnType<typeof getFiles.failed>
  | ReturnType<typeof uploadFile.started>
  | ReturnType<typeof uploadFile.succeeded>
  | ReturnType<typeof uploadFile.failed>
  | ReturnType<typeof downloadFile.started>
  | ReturnType<typeof downloadFile.succeeded>
  | ReturnType<typeof downloadFile.failed>
  | ReturnType<typeof uploadPdfThumbnail.started>
  | ReturnType<typeof uploadPdfThumbnail.succeeded>
  | ReturnType<typeof uploadPdfThumbnail.failed>
