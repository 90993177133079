import { combineReducers } from 'redux'
import { CombinedReducers } from '@models/index'

import filesReducer from './files'

const RootReducer: CombinedReducers = combineReducers({
  files: filesReducer,
})

export default RootReducer
