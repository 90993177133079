import React from 'react'

import { CognitoUserPool } from 'amazon-cognito-identity-js'
import awsConfiguration from '../../awsConfiguration'
import useReactRouter from 'use-react-router'

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
})

const SignOut: React.FC = () => {
  const { history } = useReactRouter()

  React.useEffect(() => {
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser) {
      cognitoUser.signOut()
      localStorage.clear()
    }
    return history.push('/signin')
  }, [])

  return null
}

export default SignOut