import axios from 'axios'
import * as FileSaver from 'file-saver'
import {
  File,
  UploadFileResponse,
  DownloadFileResponse,
  UploadPdfThumbnailResponse,
} from '@models/index'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.API_GW_BASIC_URL!,
  timeout: 7000
}

export const getUploadUrlFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getSignedUrl = async (request: { name: string, mode: string, type: string }) => {
    const requestURI = encodeURI(`files/signed-url?name=${request.name}&mode=${request.mode}&type=${request.type}`)
    try {
      const response = await instance.get(requestURI)
      if (response.status !== 200) {
        throw new Error(`Failed: status code is ${response.status}`)
      }
      const result: UploadFileResponse = response.data
      return result.uploadUrl
    } catch(err) {
      throw new Error(err)
    }
  }
  return getSignedUrl
}

export const uploadFileFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const uploadFile = async (request: { uploadUrl: string, formData: any, type: string }) => {
    return await fetch(request.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': request.type },
      body: request.formData,
    })
      .then((response: any) => {
        if (response.ok) {
          return
        }
        throw new Error('There was something wrong')
      })
      .catch((err: any) => {
        console.error(err)
        throw new Error(err)
      })
  }
  return uploadFile
}

export const getDownloadUrlFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getSignedUrl = async (request: { id: string, mode: string }) => {
    try {
      const response = await instance.get(`files/signed-url?id=${request.id}&mode=${request.mode}`)
      if (response.status !== 200) {
        throw new Error(`Failed: status code is ${response.status}`)
      }
      const result: DownloadFileResponse = response.data
      return result.downloadUrl
    } catch(err) {
      throw new Error(err)
    }
  }
  return getSignedUrl
}

export const downloadFileFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const downloadFile = async (request: { downloadUrl: string, fileName: string }) => {
    // also seeking how to download with axios
    return await fetch(request.downloadUrl, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response: any) => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error('There was something wrong')
      })
      .then((blob: any) => {
        FileSaver.saveAs(blob, request.fileName)
        return
      })
      .catch((err: any) => {
        console.error(err)
        throw new Error(err)
      })
  }
  return downloadFile
}

export const getFilesFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getFiles = async () => {
    try {
      const response = await instance.get(`files`)
      if (response.status !== 200) {
        throw new Error(`Failed: status code is ${response.status}`)
      }
      const files: File[] = response.data
      return files
    } catch(err) {
      throw new Error(err)
    }
  }
  return getFiles
}

export const getUploadThumbnailUrlFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getSignedUrl = async (request: { id: string, name: string, fileName: string, type: string }) => {
    const requestURI = encodeURI(`thumbnail/signed-url?id=${request.id}&name=${request.name}&fileName=${request.fileName}&type=${request.type}`)
    try {
      const response = await instance.get(requestURI)
      if (response.status !== 200) {
        throw new Error(`Failed: status code is ${response.status}`)
      }
      const result: UploadPdfThumbnailResponse = response.data
      return result.uploadUrl
    } catch(err) {
      throw new Error(err)
    }
  }
  return getSignedUrl
}

export const uploadPdfThumbnailFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const uploadPdfThumbnail = async (request: { uploadUrl: string, formData: any, type: string }) => {
    return await fetch(request.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': request.type },
      body: request.formData,
    })
      .then((response: any) => {
        if (response.ok) {
          return
        }
        throw new Error('There was something wrong')
      })
      .catch((err: any) => {
        console.error(err)
        throw new Error(err)
      })
  }
  return uploadPdfThumbnail
}