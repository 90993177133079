import React from 'react'
import ReactDOM from 'react-dom'
import '@public/index.css'
import App from './components/App'
import createSagaMiddleware from 'redux-saga'

import { createStore, compose, applyMiddleware } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import RootReducer from '@reducers'
import RootSaga from '@sagas'


const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  RootReducer,
  compose(applyMiddleware(sagaMiddleware), devToolsEnhancer({})),
)

sagaMiddleware.run(RootSaga)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
