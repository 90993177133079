import React from 'react'
import Sidebar from 'react-sidebar'
import { getFiles, downloadFile, uploadFile, uploadPdfThumbnail } from '@actions/files/index'
import { useDispatch, useSelector } from 'react-redux'
import { File, FilesState, CombinedReducers } from '@models/index'
import Dropzone from 'react-dropzone'
import HashLoader from 'react-spinners/HashLoader'
import uploadImage from '@public/download.png'
import useReactRouter from 'use-react-router'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import awsConfiguration from '../../awsConfiguration'

import styledComponents from '@utils/styled-components'
const BodyComponent = styledComponents.BodyComponent
const MainComponent = styledComponents.MainComponent
const FileComponent = styledComponents.FileComponent
const ClickableFileComponent = styledComponents.ClickableFileComponent
const FooterComponent = styledComponents.FooterComponent
const PDFUploaderComponent = styledComponents.PDFUploaderComponent
const LoaderWrapperComponent = styledComponents.LoaderWrapperComponent
const LoaderBackgroundComponent = styledComponents.LoaderBackgroundComponent
const CommandButtonComponent = styledComponents.CommandButtonComponent
const CommandButtonWrapperComponent = styledComponents.CommandButtonWrapperComponent
const ThumbnailImageComponment = styledComponents.ThumbnailImageComponment

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
})

const Main: React.FC = () => {
  const dispatch = useDispatch()
  const { history } = useReactRouter()

  const filesState: FilesState = useSelector((state: CombinedReducers) => {
    return state.files
  })
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false)
  const [selectedFile, setSelectedFile] = React.useState<File>({ id: '', name: '', thumbnailPath: '' })
  const showDetailHandler = (id: string, name: string, thumbnailPath: string) => {
    setSidebarOpen(true)
    setSelectedFile({ id, name, thumbnailPath })
  }
  const downloadFileHandler = (id: string, name: string) => {
    dispatch(downloadFile.started({ id, name, mode: 'download' }))
  }
  const uploadFileHandler = (acceptedFiles: any) => {
    // If the same name file in filesState.files exists, then nothing returned
    if (filesState.files.some((file: File) => file.name === acceptedFiles[0].name)) {
      alert('重複する名前のPDFファイルがすでに存在するためアップロードができません。PDFファイル名は一意である必要があります。')
      return
    }
    dispatch(
      uploadFile.started({
        formData: acceptedFiles[0],
        name: acceptedFiles[0].name,
        mode: 'upload',
        type: acceptedFiles[0].type,
      })
    )
  }

  const uploadPdfThumbnailHandler = (
    acceptedFiles: any,
    id: string,
    fileName: string
  ) => {
    // If the same name file in filesState.files exists, then nothing returned
    if (filesState.files.some((file: File) => file.thumbnailPath === `thumbnails/${acceptedFiles[0].name}`)) {
      alert('重複する名前のサムネイルがすでに存在するためアップロードができません。サムネイル名はすべてのPDFファイルを通して一意である必要があります。')
      return
    }
    dispatch(
      uploadPdfThumbnail.started({
        formData: acceptedFiles[0],
        name: acceptedFiles[0].name,
        type: acceptedFiles[0].type,
        id,
        fileName,
      })
    )
  }

  React.useEffect(() => {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) return history.push('/signin')
    dispatch(getFiles.started())
  }, [])

  const loaderCondition: Boolean =
    filesState.startedGetFiles
    || filesState.startedUploadFile
    || filesState.startedDownloadFile
    || filesState.startedUploadPdfThumbnail

  return (
    <>
      {
        loaderCondition ? (
          <LoaderWrapperComponent>
            <LoaderBackgroundComponent>
              <HashLoader
                size={150}
                color={'#7fffd4'}
                loading={loaderCondition}
              />
            </LoaderBackgroundComponent>
          </LoaderWrapperComponent>
        ) : null
      }
      <BodyComponent>
        <MainComponent>
          <div style={{ width: '90%', height: '100%', margin: '0 auto' }}>
            <Sidebar
              sidebar={
                sidebarOpen
                ? (
                  <div style={{ width: '400px' }}>
                    <div style={{ padding: '10px' }}>
                      <h3>{selectedFile.name}</h3>
                    </div>
                    {
                      selectedFile.thumbnailPath ?
                        <ThumbnailImageComponment
                          src={`${process.env.S3_BUCKET_FQDN}${selectedFile.thumbnailPath}`}
                        /> : null
                    }
                    <CommandButtonWrapperComponent>
                      <CommandButtonComponent
                        onClick={() =>
                          downloadFileHandler(selectedFile.id, selectedFile.name)
                        }
                      >PDFファイルをダウンロードする</CommandButtonComponent>
                    </CommandButtonWrapperComponent>
                    <Dropzone
                      onDrop={
                        (acceptedFiles: any) =>
                          uploadPdfThumbnailHandler(acceptedFiles, selectedFile.id, selectedFile.name)
                      }
                    >
                      {({getRootProps, getInputProps}: any) => (
                        <CommandButtonWrapperComponent {...getRootProps()}>
                          <input {...getInputProps()} />
                          <CommandButtonComponent>サムネイルをアップロードする</CommandButtonComponent>
                        </CommandButtonWrapperComponent>
                      )}
                    </Dropzone>
                  </div>
                )
                : <></>
              }
              open={sidebarOpen}
              onSetOpen={setSidebarOpen}
              styles={{
                root: { position: 'none' },
                sidebar: { background: '#dcdcdc' },
                content: { position: 'relative' },
              }}
              pullRight={true}
            >
              {
                filesState.files.map((file: File, index: number) => (
                    <div key={index} style={{ width: '25%', float: 'left' }}>
                      <div style={{ padding: '20px' }}>
                        <FileComponent>
                          <ClickableFileComponent
                            onClick={() => showDetailHandler(file.id, file.name, file.thumbnailPath)}
                          >
                            <div style={{ height: '90%' }}>
                              {
                                file.thumbnailPath ?
                                  <ThumbnailImageComponment
                                    src={`${process.env.S3_BUCKET_FQDN}${file.thumbnailPath}`}
                                  /> : null
                              }
                            </div>
                            <div style={{ height: '10%', overflowY: 'hidden' }}>
                              <h3 style={{ margin: '0px' }}>{file.name}</h3>
                            </div>
                          </ClickableFileComponent>
                        </FileComponent>
                      </div>
                    </div>
                  ))
              }
            </Sidebar>
          </div>
        </MainComponent>
        <FooterComponent>
          <Dropzone onDrop={(acceptedFiles: any) => uploadFileHandler(acceptedFiles)}>
            {({getRootProps, getInputProps}: any) => (
              <div {...getRootProps()} style={{ height: '100%', width: '100%', outline: 'none' }}>
                <input {...getInputProps()} />
                <PDFUploaderComponent>
                  <img src={uploadImage} style={{ height: '100%' }} />
                </PDFUploaderComponent>
              </div>
            )}
          </Dropzone>
        </FooterComponent>
      </BodyComponent>
    </>
  )
}

export default Main
