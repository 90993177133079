import { FilesAction } from '@actions/files/index'
import * as ActionType from '@actions/files/types'
import { FilesState } from '@models/index'

export const initialState: FilesState = {
  files: [],
  startedGetFiles: false,
  startedUploadFile: false,
  startedDownloadFile: false,
  startedUploadPdfThumbnail: false,
  getFilesStatus: {
    succeeded: false,
    failed: false,
  },
  uploadFileStatus: {
    succeeded: false,
    failed: false,
  },
  downloadFileStatus: {
    succeeded: false,
    failed: false,
  },
  uploadPdfThumbnailStatus: {
    succeeded: false,
    failed: false,
  },
}

const filesReducer = (
  state: FilesState = initialState,
  action: FilesAction,
): FilesState => {
  switch(action.type) {
    case ActionType.STARTED_GET_FILES:
      return {
        ...state,
        startedGetFiles: true,
      }
    case ActionType.SUCCEEDED_GET_FILES:
      return {
        ...state,
        files: action.payload.response.files,
        startedGetFiles: false,
        getFilesStatus: {
          succeeded: true,
          failed: false
        },
      }
    case ActionType.FAILED_GET_FILES:
      return {
        ...state,
        startedGetFiles: false,
        getFilesStatus: {
          succeeded: false,
          failed: true,
        },
      }
    case ActionType.STARTED_UPLOAD_FILE:
      return {
        ...state,
        startedUploadFile: true,
      }
    case ActionType.SUCCEEDED_UPLOAD_FILE:
      return {
        ...state,
        files: action.payload.response.files,
        startedUploadFile: false,
        uploadFileStatus: {
          succeeded: true,
          failed: false,
        },
      }
    case ActionType.FAILED_UPLOAD_FILE:
      return {
        ...state,
        startedUploadFile: false,
        uploadFileStatus: {
          succeeded: false,
          failed: true,
        },
      }
    case ActionType.STARTED_DOWNLOAD_FILE:
      return {
        ...state,
        startedDownloadFile: true,
      }
    case ActionType.SUCCEEDED_DOWNLOAD_FILE:
      return {
        ...state,
        startedDownloadFile: false,
        downloadFileStatus: {
          succeeded: true,
          failed: false,
        },
      }
    case ActionType.FAILED_DOWNLOAD_FILE:
      return {
        ...state,
        startedDownloadFile: false,
        downloadFileStatus: {
          succeeded: false,
          failed: true,
        },
      }
    case ActionType.STARTED_UPLOAD_PDF_THUMBNAIL:
      return {
        ...state,
        startedUploadPdfThumbnail: true,
      }
    case ActionType.SUCCEEDED_UPLOAD_PDF_THUMBNAIL:
      return {
        ...state,
        files: action.payload.response.files,
        startedUploadPdfThumbnail: false,
        uploadPdfThumbnailStatus: {
          succeeded: true,
          failed: false,
        },
      }
    case ActionType.FAILED_UPLOAD_PDF_THUMBNAIL:
      return {
        ...state,
        startedUploadPdfThumbnail: false,
        uploadPdfThumbnailStatus: {
          succeeded: false,
          failed: true,
        },
      }
    default:
      console.warn(`There is something wrong with the action passed: ${JSON.stringify(action, null, 2)}`)
      return state
  }
}

export default filesReducer