import React from 'react'
import logoImage from '@public/logo.png'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js'
import awsConfiguration from '../../awsConfiguration'
import useReactRouter from 'use-react-router'

import styledComponents from '@utils/styled-components'
const BodyComponent = styledComponents.BodyComponent
const MainComponent = styledComponents.MainComponent

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
})

const SignIn: React.FC = () => {
  const { history } = useReactRouter()
  const [email, setEmail] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')

  const emailHandler = (input: string) => {
    setEmail(input)
  }
  const passwordHandler = (input: string) => {
    setPassword(input)
  }
  const signInHandler = () => {
    const authenticationDetails = new AuthenticationDetails({
      Username : email,
      Password : password
    })
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    })

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: any) => {
        const accessToken = result.getAccessToken().getJwtToken()
        setEmail('')
        setPassword('')
        history.push('/')
      },
      onFailure: (err: any) => {
        console.error(err)
      }
    })
  }

  return (
    <BodyComponent>
      <MainComponent>
        <div style={{ width: '40%', height: '200px', margin: '0 auto', padding: '40px' }}>
          <div style={{
            width: '100%',
            height: '100%',
            borderRadius: '10px',
            border: '0.5px solid #000000',
            backgroundColor: 'rgba(0,0,0,0.3)',
            filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,1))',
          }}>
            <div style={{ width: '80%', margin: '0 auto' }}>
              <div style={{ width: '100%', height: '80px', padding: '20px 0px' }}>
                <div style={{ height: '80px', width: '80px', float: 'left' }}>
                  <img src={logoImage} style={{ height: '100%' }} />
                </div>
                <div style={{ float: 'left' }}>
                  <h1 style={{ color: '#ffffff' }}>Sign in</h1>
                </div>
              </div>
              <input
                type='text'
                onChange={(event: { target: { value: string } }) => emailHandler(event.target.value)}
                placeholder='email'
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  width: '100%',
                  padding: '0px',
                }}
              />
              <input
                type='password'
                onChange={(event: { target: { value: string } }) => passwordHandler(event.target.value)}
                placeholder='password'
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  width: '100%',
                  padding: '0px',
                }}
              />
              <button
                onClick={() => signInHandler()}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  width: '100%',
                  backgroundColor: '#4caf50',
                  color: '#ffffff',
                }}
              >Sign in</button>
            </div>
          </div>
        </div>
      </MainComponent>
    </BodyComponent>
  )
}

export default SignIn
