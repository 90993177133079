import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from '@actions/files/types'
import { getFiles, uploadFile, downloadFile, uploadPdfThumbnail } from '@actions/files/index'
import {
  getFilesFactory,
  getUploadUrlFactory,
  uploadFileFactory,
  getDownloadUrlFactory,
  downloadFileFactory,
  getUploadThumbnailUrlFactory,
  uploadPdfThumbnailFactory,
} from '@services/files/api'

function* runUploadFile(action: ReturnType<typeof uploadFile.started>) {
  const name = action.payload.name
  const mode = action.payload.mode
  const type = action.payload.type
  try {
    const uploadUrl = yield call(getUploadUrlFactory(), { name, mode, type })

    yield call(uploadFileFactory(), { uploadUrl, formData: action.payload.formData, type })

    const uploadedFiles = yield call(getFilesFactory())

    yield put(uploadFile.succeeded(action.payload.request, { files: uploadedFiles }))
  } catch(err) {
    console.error('Failed to upload file')
    yield put(uploadFile.failed(action.payload.request, { files: [] }))
  }
}

function* runDownloadFile(action: ReturnType<typeof downloadFile.started>) {
  const id = action.payload.id
  const mode = action.payload.mode
  try {
    const downloadUrl = yield call(getDownloadUrlFactory(), { id, mode })

    yield call(downloadFileFactory(), { downloadUrl, fileName: action.payload.name })

    yield put(downloadFile.succeeded(action.payload.request))
  } catch(err) {
    console.error('Failed to download file')
    yield put(downloadFile.failed(action.payload.request, { files: [] }))
  }
}

function* runGetFiles(action: ReturnType<typeof getFiles.started>) {
  try {
    const indexFiles = yield call(getFilesFactory())

    yield put(getFiles.succeeded({ files: indexFiles }))
  } catch(err) {
    console.error('Failed to get files')
    yield put(getFiles.failed({ files: [] }))
  }
}

function* runUploadPdfThumbnail(action: ReturnType<typeof uploadPdfThumbnail.started>) {
  const id = action.payload.id
  const name = action.payload.name
  const fileName = action.payload.fileName
  const type = action.payload.type
  try {
    const uploadUrl = yield call(getUploadThumbnailUrlFactory(), { id, name, fileName, type })

    yield call(uploadPdfThumbnailFactory(), { uploadUrl, formData: action.payload.formData, type })

    const uploadedFiles = yield call(getFilesFactory())

    yield put(uploadPdfThumbnail.succeeded(action.payload.request, { files: uploadedFiles }))
  } catch(err) {
    console.error('Failed to upload file')
    yield put(uploadPdfThumbnail.failed(action.payload.request, { files: [] }))
  }
}

export function* watchFileSaga() {
  yield takeLatest(Action.STARTED_GET_FILES, runGetFiles)
  yield takeLatest(Action.STARTED_UPLOAD_FILE, runUploadFile)
  yield takeLatest(Action.STARTED_DOWNLOAD_FILE, runDownloadFile)
  yield takeLatest(Action.STARTED_UPLOAD_PDF_THUMBNAIL, runUploadPdfThumbnail)
}
